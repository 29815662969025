import React from 'react';
import styled from 'styled-components';

const Content = ({ className, children, content }) => {
  if (children) {
    return (
      <div className={className}>{children}</div>
    );
  }
  if (content) {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }
  // No content or children are passed to this component :(
  return <div />;
};
export default styled(Content)`
  h1 {
    font-size: 2rem;
    line-height: 2.722rem;
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1.7rem;
    line-height: 2.278rem;
    margin-bottom: .9rem;
  }
  h3 {
    font-size: 1.556rem;
    line-height: 2.5rem;
    margin-bottom: .8rem;
  }
  h4 {
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom: .6rem;
  }
  a {
    color: ${(props) => props.theme.primaryColor};
    text-decoration: none;
  }
  p a {
    position: relative;
    padding-right: .5rem;
    &:after {
      position: absolute;
      top: 50%;
      right: .2rem;
      border-style: solid;
      border-width: 0.08rem 0.08rem 0 0;
      content: '';
      display: inline-block;
      height: 0.45rem;
      transform: rotate(-45deg);
      vertical-align: top;
      width: 0.45rem;
      transform: rotate(45deg) translateY(-50%);
      transition: all 0.1s ease-in-out;
    }
    &:hover {
      &:after {
        right: .1rem;
      }
    }
  }
  p {
    margin-bottom: 1rem;
  }
  address, p, table, ul, ol {
    line-height: 1.611rem;
  }
  figure {
    margin: 0 0 1rem 0;
  }
  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      padding-left: 1rem;
      position: relative;
      margin-bottom: 1rem;
      &:before {
        position: absolute;
        top: .6rem;
        left: 0;
        display: block;
        content: '';
        width: .5rem;
        height: .5rem;
        background-color: ${(props) => props.theme.primaryColor};
        border-radius: 50%;
      }
    }
  }

  .alignright {
    @media screen and (min-width: 778px) {
      float: right;
      margin: 0 0 10px 30px;
    }
  }

  .has-text-align-center {
    text-align: center;
  }

  .has-media-on-the-right {
    .wp-block-media-text__media {
      float: right;
    }
  }

  .wp-block-spacer {
    clear: both;
  }

  .wp-block-columns {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      grid-gap: ${(props) => props.theme.grid.columnGap};
      grid-template-columns: 1fr 1fr;
    }
  }
`;
